@import "@styles-v2/common";

.container {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 32px;
  justify-content: center;
  padding: 32px;
  width: auto;
  max-width: 100%;
}

.top {
  display: flex;
  align-items: center;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 16px;
  justify-content: center;
  padding: 0;
  width: 100%;
}

.planTitle {
  color: #181d27ff;
  font-family: 'Tsukushi Gothic', sans-serif;
  font-size: 18px;
  font-weight: 700;
  height: auto;
  letter-spacing: normal;
  line-height: 1.5;
  margin: 0 0 0 0;
  mix-blend-mode: normal;
  opacity: .9;
  text-align: center;
  text-shadow: none;
  width: auto;
  z-index: 3;
  max-width: 100%;
  justify-content: center;
}

.planText {
  color: #181d27ff;
  font-family: 'Tsukushi Gothic', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: auto;
  letter-spacing: normal;
  line-height: 1.75;
  margin: 0 0 0 0;
  mix-blend-mode: normal;
  opacity: .9;
  text-align: center;
  text-shadow: none;
  width: auto;
  z-index: 3;
  max-width: 100%;
  justify-content: center;
}

.detail {
  align-items: center;
  background: #fafafaff;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 16px;
  justify-content: center;
  padding: 16px;
  width: 100%;
}

.item {
  display: flex;
  align-items: flex-start;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
}

.check {
  height: 24px;
  width: 24px;
  max-width: 100%;
  position: relative;

  &::before {
    background-position: 50%;
    background-size: cover;
    border-radius: inherit;
    content: '';
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: inherit;
    width: 100%;
    background-image: url('../../../public/images/common/plan_detail_item_check.svg');
  }
}

.itemText {
  color: #181d27ff;
  flex: 1;
  font-family: 'Tsukushi Gothic', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: auto;
  letter-spacing: normal;
  line-height: 1.75;
  margin: 0 0 0 0;
  mix-blend-mode: normal;
  opacity: .9;
  text-align: left;
  text-shadow: none;
  width: auto;
  max-width: 100%;
  justify-content: flex-start;
}

.bottom {
  display: flex;
  align-items: center;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 16px;
  justify-content: center;
  padding: 0;
  width: 100%;
}

.price {
  display: flex;
  align-items: center;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
  justify-content: center;
  padding: 0;
  width: 100%;
}

.cost {
  display: flex;
  align-items: flex-end;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 4px;
  justify-content: center;
  padding: 0;
  width: 100%;
}

.number {
  color: #181d27ff;
  font-family: 'Tsukushi Gothic', sans-serif;
  font-size: 32px;
  font-weight: 700;
  height: auto;
  letter-spacing: normal;
  line-height: 1;
  margin: 0 0 0 0;
  mix-blend-mode: normal;
  opacity: .9;
  text-align: left;
  text-shadow: none;
  width: auto;
  z-index: 3;
  max-width: 100%;
  justify-content: flex-start;
}

.tax {
  color: #535862ff;
  font-family: 'Tsukushi Gothic', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: auto;
  letter-spacing: normal;
  line-height: 1.75;
  margin: 0 0 0 0;
  mix-blend-mode: normal;
  opacity: .9;
  text-align: center;
  text-shadow: none;
  width: auto;
  z-index: 3;
  max-width: 100%;
  justify-content: center;
}

.per {
  color: #535862ff;
  font-family: 'Tsukushi Gothic', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: auto;
  letter-spacing: normal;
  line-height: 1.75;
  margin: 0 0 0 0;
  mix-blend-mode: normal;
  opacity: .9;
  text-align: center;
  text-shadow: none;
  width: auto;
  z-index: 3;
  max-width: 100%;
  justify-content: center;
}

.priceDescription {
  color: #535862ff;
  font-family: 'Tsukushi Gothic', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: auto;
  letter-spacing: normal;
  line-height: 1.75;
  margin: 0 0 0 0;
  mix-blend-mode: normal;
  opacity: .9;
  text-align: center;
  text-shadow: none;
  width: auto;
  z-index: 3;
  max-width: 100%;
  justify-content: center;
}

.link {
  @include button-cleared;

  display: flex;
  align-items: center;
  background: #7f56d9ff;
  box-shadow: 3px 3px 0 #53389e33;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 24px;
  justify-content: center;
  padding: 24px;
  width: 100%;

  &.loading {
    position: relative;
  }

  &:hover {
    background: #6941c6ff;
  }
}

.btnContent {
  display: flex;
  justify-content: center;
  align-items: center;

  &.loading {
    visibility: hidden;
  }
}

.loadingIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.linkText {
  color: #fff;
  font-family: 'Tsukushi Gothic', sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: auto;
  letter-spacing: normal;
  line-height: 1.3;
  text-align: left;
  width: auto;
  max-width: 100%;
  justify-content: flex-start;
}
